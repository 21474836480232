// This variable is deprecated. Use $inuit-flexgrid-spacing-sizes instead.

$inuit-flexgrid-gutter-width: $spacer-ml !default;

// Specify a map of possible gutter widths. By default we use the global
// spacing units provided by inuitcss and their conventional class
// name suffixes (--tiny, --small, --large etc).

$inuit-flexgrid-spacing-sizes: (
    null: $inuit-flexgrid-gutter-width,
    "--tiny": $inuit-global-spacing-unit-tiny,
    "--small": $inuit-global-spacing-unit-small,
    "--large": $inuit-global-spacing-unit-large,
    "--huge": $inuit-global-spacing-unit-huge,
    "--flush": 0,
) !default;

// Specify possible alignment values

$inuit-flexgrid-alignment-values: (
    "--top": "flex-start",
    "--middle": "center",
    "--bottom": "flex-end",
    "--baseline": "baseline",
    "--stretch": "stretch",
) !default;

// Specify possible justify values

$inuit-flexgrid-justify-values: (
    "--left": "flex-start",
    "--center": "center",
    "--right": "flex-end",
    "--between": "space-between",
    "--around": "space-around",
) !default;

// Check that the chosen size factors are unitless, integer numbers.

@each $_inuit-spacing-unit in
    $inuit-global-spacing-unit-factor-tiny
    $inuit-global-spacing-unit-factor-small
    $inuit-global-spacing-unit-factor-large
    $inuit-global-spacing-unit-factor-huge {

    @if (type-of($_inuit-spacing-unit) == number) {

        @if (unitless($_inuit-spacing-unit) == false) {
            @error "`#{$_inuit-spacing-unit}` needs to be unitless.";
        }

        @if ($_inuit-spacing-unit != ceil($_inuit-spacing-unit)) {
            @error "`#{$_inuit-spacing-unit}` needs to be an integer.";
        }

    } @else {
        @error "`#{$_inuit-spacing-unit}` needs to be a number.";
    }

}


/* The grid
   ========================================================================== */

   .o-grid {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding: 0;
    z-index: 10;
    position: relative;
}

/**
 * 1. Cells are full-width and stack on top of each other by default.
 */

.o-grid__cell {
    flex: 0 1 auto;
    width: 100%; /* [1] */
}


/* Gutters
   ========================================================================== */

/**
 * 1. The map $inuit-flexgrid-spacing-sizes contains a `null` key which
 *    will be our default gutter size.
 * 2. Add a negative margin on one side only. That way we aren't required to use
 *    a wrapper with the same amount of spacing, though it's common to do so.
 * 3. Neutralize the left margin on `o-grid`.
 */

@each $size-namespace, $size in $inuit-flexgrid-spacing-sizes {
    .o-grid#{$size-namespace} { /* [1] */
        margin-left: -$size; /* [2] */
        & > .o-grid__cell {
            padding-left: $size; /* [3] */
        }
    }
}


/* Automatically size cells by distributing them equally
   ========================================================================== */

.o-grid--auto {
    & > .o-grid__cell {
        flex: 1 0 0;
    }
}


/* Horizontal alignment
   ========================================================================== */

@each $namespace, $value in $inuit-flexgrid-justify-values {
    .o-grid#{$namespace} {
        justify-content: #{$value};
    }
}


/* Pull behavior
   ========================================================================== */

.o-grid__cell--pull-left {
    margin-right: auto;
}

.o-grid__cell--pull-right {
    margin-left: auto;
}


/* Vertical alignment
   ========================================================================== */

@each $namespace, $value in $inuit-flexgrid-alignment-values {
    .o-grid#{$namespace} {
        align-items: #{$value};
    }
}

@each $namespace, $value in $inuit-flexgrid-alignment-values {
    .o-grid__cell#{$namespace} {
        align-self: #{$value};
    }
}


/* Content distribution
   ========================================================================== */

.o-grid--reverse {
    flex-direction: row-reverse;
}

.o-grid--column {
    flex-direction: column;
}

.o-grid--column-reverse {
    flex-direction: column-reverse;
}