:root {
    --header-bar-height: 2px;
    --header-spacing-value: #{$font-size-base-minus-1};
}
@include mq($from: 400px) {
    :root {
        --header-bar-height: 4px;
        --header-spacing-value: #{$font-size-base-plus-2};
    }
}
@include mq($from: desktop) {
    :root {
        --header-bar-height: 6px;
        --header-spacing-value: #{$font-size-base-plus-1};
    }
}

.c-header {
    color: $white;
	background-color: $tnmDarkGray;
    position: relative;
    width: 100%;
    z-index: 100;
    top: 0;
}

.c-header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2vw;
    padding: $spacer-m $spacer-s;
    max-width: 1600px;
    margin: 0 auto;
}

.c-header__logo {
    display: inline-block;
    width: auto;
    height: $font-size-base-plus-4;
    @include mq($from: desktop) {
        height: 100px;
    }
    @include mq($from: wide) {
        height: 120px;
    }
    figure {
        height: 100%;
        width: auto;
        margin: 0;
        img {
            height: 100%;
            width: 100%;
        }
    }
    svg {
        height: 100%;
        width: auto;
    }
}

.c-header__logo-img--mobile {
    display: inline-block;
    @include mq($from: desktop) {
        display: none;
    }
}

.c-header__logo-img--desktop {
    display: none;
    @include mq($from: desktop) {
        display: inline-block;
    }
}

.c-header-nav__text-hidden-mobile {
    display: none;
    @include mq($from: tablet) {
        display: inline;
    }
}

.c-header-nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--header-spacing-value);
    li {
        margin: 0;
    }
}


.c-header-nav__link {
    @include ff-heading;
    text-decoration: none;
    @include font-size-base-minus-1;
    color: $white;
    @include mq($from: mobile) {
        @include font-size-base;
    }
    @include mq($from: tablet) {
        @include font-size-base-plus-1;
    }
    &:hover, &:focus {
        color: rgba($white, 0.85);
    }
}

.c-header--has-scrolled {
    @include mq($until: desktop) {
        backdrop-filter: blur(2px);
    }
}

.c-header-nav__link--tickets {
    @include c-btn($tnmVanilla, $black);
    @include font-size-base;
    padding: $spacer-s $spacer-m;
    &:hover,
    &:focus {
        @include c-btn--hover($tnmVanilla, $black);
    }
    &:active {
        @include c-btn--active($tnmVanilla, $black);
    }
}