.c-footer {
    background-color: $black;
    color: $white;
    .c-footer__wrap {
        padding-top: $spacer-l;
    }
    .c-col {
        margin-bottom: $spacer-ml;
        @include mq($from: tablet) {
            margin-bottom: $spacer-l;
        }
    }
}

.c-footer__address {
    @include font-size-base;
    @include ff-body;
    margin-bottom: .15em;
    &::first-line {
        @include font-size-base-plus-1;
        @include ff-heading;
    }
}

a.c-footer__map-link, a.c-footer__phone {
    display: block;
    @include font-size-base;
    @include ff-heading;
    text-decoration: none !important;
    margin-bottom: $spacer-sm;
    @include mq($from: tablet) {
        margin-bottom: $spacer-m;
    }
    &:hover, &:focus {
        color: currentColor;
    }

}

a.c-footer__phone {
    display: inline-block;
    @include font-size-base;
    @include ff-heading;
    text-decoration: none !important;
    margin-bottom: $spacer-sm;
    @include mq($from: tablet) {
        margin-bottom: $spacer-m;
    }
    &::before {
        @include fa-solid;
        content: "\f8d3";
        font-size: 90%;
        opacity: .5;
        display: inline-block;
        color: currentColor;
        transition: transform 150ms ease-in-out;
        margin-right: $spacer-s;
    }
}

.c-footer__socials {
    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            margin: 0;
            @include font-size-base-plus-1;
            a {
                display: inline-block;
                padding: $spacer-xs $spacer-xs;
                opacity: .5;
                &:hover, &:focus {
                    opacity: 1;
                    color: currentColor;

                }
            }
        }
    }
}

.c-footer__mailing-list {
    max-width: 320px;
    h3 {
        @include font-size-base-plus-1;
        @include ff-heading;
        margin-bottom: .25em;
        margin-top: 0;
    }
    p {
        text-wrap: balance;
    }
    .c-btn.c-btn--ghost {
        display: inline-block !important;
        padding: ($inuit-button-padding-vertical - $inuit-button-ghost-border-width) ($spacer-ml - $inuit-button-ghost-border-width) !important;
    }
}

.c-footer__nav {
    margin: 0;
    padding: 0;
    columns: 2 auto;
    column-fill: balance;
    @include mq($from: tablet) {
        columns: 3 auto;
    }
    li {
        margin: 0;
        a {
            @include font-size-base;
            @include ff-heading;
            display: inline-block;
            text-decoration: none;
            margin-bottom: $spacer-s;
            @include mq($from: tablet) {
                margin-bottom: $spacer-m;
            }
        }
    }
}

.c-footer .c-footer__meta-container {
    margin-bottom: $spacer-s;
    margin-top: $spacer-m;
}

.c-footer__meta {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: rgba($color: white, $alpha: .5);
    p {
        @include font-size-base-minus-1;
    }
}

.c-footer__credit {
    a {
        text-decoration: none !important;
        &:hover, &:focus {
            color: rgba($color: white, $alpha: 1);
        }
    }
}