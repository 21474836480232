///* ========================================================================
//   #FONT FILES
//   ======================================================================== */

// Add your font-face links to fonts here

// Add your font-face links to fonts here


$font-path: "https://neon-museum-las-vegas-assets.s3.amazonaws.com/qa/fonts";

@font-face {
    // font-family: 'Custom Font';
    // src: url('../fonts/custom.eot');
    // src: url('../fonts/custom.eot?#iefix') format('embedded-opentype'),
    //      url('../fonts/custom.woff2') format('woff2'),
    //      url('../fonts/custom.woff') format('woff');
    // font-weight:  400;
    // font-style:   italic;
    // font-stretch: normal;
}


@font-face {
    font-family: "Trade Gothic LT";
    src: url("#{$font-path}/tradegothicltstd-webfont.woff2") format("woff2"),
        url("#{$font-path}/tradegothicltstd-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Trade Gothic LT Bold";
    src: url("#{$font-path}/tradegothicltstd-bold-webfont.woff2") format("woff2"),
        url("#{$font-path}/tradegothicltstd-bold-webfont.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Trade Gothic LT";
    src: url("#{$font-path}/tradegothicltstd-obl-webfont.woff2") format("woff2"),
        url("#{$font-path}/tradegothicltstd-obl-webfont.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-variant: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: "Trade Gothic LT Fallback";
    src: local(Arial);
    size-adjust: 104%;
    ascent-override: normal;
    descent-override: normal;
    line-gap-override: normal;
}

@font-face {
    font-family: "Trade Gothic LT Bold Fallback";
    src: local(Arial Narrow);
    size-adjust: 104%;
    ascent-override: normal;
    descent-override: normal;
    line-gap-override: normal;
}

$font-family-trade-gothic: "Trade Gothic LT";
$font-family-trade-gothic-fallback: "Trade Gothic LT Fallback", sans-serif;

$font-family-trade-gothic-bold: "Trade Gothic LT Bold";
$font-family-trade-gothic-bold-fallback: "Trade Gothic LT Bold Fallback", serif;

/* ==========================================================================
   #Fonts
   ========================================================================== */


// Font Face

@mixin ff-tg-standard {
    font-family: $font-family-trade-gothic, $font-family-trade-gothic-fallback;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
}

@mixin ff-tg-bold {
    font-family: $font-family-trade-gothic-bold, $font-family-trade-gothic-fallback;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
}

@mixin fonts {
    @include ff-tg-standard;
}

@mixin ff-body {
    @include ff-tg-standard;
}

@mixin ff-heading {
    @include ff-tg-bold;
}

@mixin font-thin {
    font-weight: 100;
}
@mixin font-light {
    font-weight: 300;
}
@mixin font-reg {
    font-weight: 400;
}
@mixin font-med {
    font-weight: 500;
}
@mixin font-semi-bold {
    font-weight: 600;
}
@mixin font-bold {
    font-weight: 700;
}
@mixin font-heavy {
    font-weight: 800;
}
@mixin font-super-heavy {
    font-weight: 800;
}
@mixin font-italic {
    font-style: italic;
}



/* Font awesome fonts
   ========================================================================== */

@mixin fa-reg {
    font-family: "Font Awesome 6 Pro";
    @include font-reg;
}

@mixin fa-light {
    font-family: "Font Awesome 6 Pro";
    @include font-light;
}

@mixin fa-solid {
    font-family: "Font Awesome 6 Pro";
    @include font-super-heavy;
}


@mixin fa-brands {
    font-family: "Font Awesome 6 Brands";
}

@mixin fa-duotone {
    font-family: "Font Awesome 6 Duotone";
}


