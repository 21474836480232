/* 
 * TNEW subnavigation
 * Contains the login/register/basket links, promo code field
 */

 .tn-subnav-component {
    position: relative;
    padding: $spacer-s 0;
    color: $black;
    margin-left: 0;
    margin-bottom: $spacer-l;
    flex-wrap: wrap;
    gap: $spacer-s;
    @include ff-heading;
    @include font-size-base-minus-1;
    border-bottom: 1px solid rgba($black, 0.15);
    @include mq($from: $desktop) {
        gap: $spacer-m;
    }

    // making sure the nav contents sit on top of the bg
    & > div {
        position: relative;
        z-index: 1;
    }

    // A lot of selectors to override the tnew madness
    // Resulting selectors target the exact same selectors as TNEW
    .tn-promo-box {
        margin-left: 0;
        margin-right: 0;

        &.promo-applied,
        &.tn-promo-applied {
            display: flex;
        }

        #tn-apply-promo {
            display: flex;
            align-items: stretch;
            justify-content: stretch;
        }

        &::before {
            display: none !important;
        }

        // Promo code input field
        .tn-text,
        input.tn-subnav-promo-code {
            // @include c-btn;
            @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-base);
            min-width: 15ch;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            text-transform: none;
            text-align: left;
            border: 1px solid rgba($black, 0.15);
        }

        // Promo code submit button
        button.tn-link,
        button.tn-subnav-promo-button {
            @include c-btn;
            @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-base);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &:hover, &:focus {
                color: $white;
            }
        }
    }

    .form-inline {
        z-index: 1;

        > .tn-text {
            @include c-btn;
            @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-base);
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            text-transform: none;
            text-align: left;
            margin-top: 0;
            display: inline-flex;
        }

        > button.btn {
            @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-base);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .tn-logged-in-text {
        display: none !important;
    }

    .tn-account-info-link,
    .tn-login-link .tn-link,
    .tn-logout-link .tn-text,
    .tn-cart-link .tn-link {
        @include c-cta;
        display: inline;
        &::after {
          display: none;
        }
        &:hover, &:focus, &:active {
            @include c-cta--hover;
        }
    }
}
