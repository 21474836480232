#tn-account-login-forgot-login-link {
    color: $black;
    @include c-cta;
    &:hover, &:focus, &:active {
        @include c-cta--hover;
    }
}

//
// Tidy up columns to look like tabbed containers

@include mq($until: tablet) {
    .tn-area-nav__list {
        border-left: 2px solid #eee;
    }
    .tn-area-nav__list-item {
        padding-left: $spacer-s;
    }
    .tn-area-nav__list-item:not(:has(a)) {
        border-left: 2px solid $highlight;
        padding-left: $spacer-m;
        
    }
}
.tn-area-nav__list-item {
    @include ff-heading;
    a {
       &:hover, &:focus {
        color: rgba($black, 0.65);
       } 
    }
}

@include mq($from: tablet) {
    main .tn-page-layout--two-column  {
        .tn-page-layout__main-container {
            border-left: 2px solid #eee;
        }
        .tn-area-nav__list-item {
            @include ff-heading;
            a {
               &:hover, &:focus {
                color: rgba($black, 0.65);
               } 
            }
        }
        .tn-area-nav__list, .tn-page-layout__sidebar-container {
            padding-right: 0;
        }
        .tn-area-nav__list-item[aria-current="page"], .tn-area-nav__list-item:not(:has(a)) {
            border-right: 2px solid $highlight;
        }
        .tn-area-nav__list-item:not(:has(a)) {
            &::before {
                @include fa-solid;
                content: "\f105";
                font-size: 90%;
                display: inline-block;
                color: $highlight;
                transition: transform 150ms ease-in-out;
            }
        }
    }
}
