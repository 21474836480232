.tn-cart-buttons__secondary-action {
    @include c-cta;
    &:hover, &:focus, &:active {
        @include c-cta--hover;
    }
}

.tn-edit-billing-link {
    @include c-cta;
    &:hover, &:focus, &:active {
        @include c-cta--hover;
    }	
}

.tn-cart-item .tn-performance-title, .tn-cart-item .tn-cart-item-summary__property--name {
	@include ff-heading;
	@include font-size-base-plus-2;
}

.tn-cart-item-summary {
	.tn-cart-item-summary__property--price-total {
		@include ff-heading;
		@include font-size-base-plus-2;
		opacity: 0.85;
	}

	.tn-cart-item-summary__property--date-time {
		@include ff-heading;
		@include font-size-base;
		opacity: 0.65;
	}

	.tn-cart-item-summary__property--additional-info {
		@include font-size-base-minus-1;
	}
}

.tn-checkout-survey-component {
	@include c-boxout;
	.checkbox label {
		@include ff-heading;
	}
	a:not([class]) {
		@include c-cta;
        display: inline;
        &::after {
          display: none;
        }
        &:hover, &:focus, &:active {
            @include c-cta--hover;
        }
	}
}

//
// Ayden Payment Form
.adyen-checkout__helper-text, .adyen-checkout__label__text {
	line-height: 150%;
}