/* ==========================================================================
   #TEXT SIZES
    Base text sizes to help with consistency throughout the project
   ========================================================================== */

   $font-size-base-minus-2: clamp(0.69rem, 0.03vi + 0.69rem, 0.72rem);
   $font-size-base-minus-1: clamp(0.83rem, 0.09vi + 0.81rem, 0.9rem);
   $font-size-base: clamp(1rem, 0.17vi + 0.96rem, 1.13rem);
   $font-size-base-plus-1: clamp(1.2rem, 0.28vi + 1.13rem, 1.41rem);
   $font-size-base-plus-2: clamp(1.44rem, 0.42vi + 1.33rem, 1.76rem);
   $font-size-base-plus-3: clamp(1.73rem, 0.63vi + 1.57rem, 2.2rem);
   $font-size-base-plus-4: clamp(2.07rem, 0.9vi + 1.85rem, 2.75rem);
   $font-size-base-plus-5: clamp(2.49rem, 1.26vi + 2.17rem, 3.43rem);
   $font-size-base-plus-6: clamp(2.99rem, 1.74vi + 2.55rem, 4.29rem);
   
   @mixin font-size-base-minus-2 {
       font-size: $font-size-base-minus-2;
       line-height: 1.5;
   }
   
   @mixin font-size-base-minus-1 {
       font-size: $font-size-base-minus-1;
       line-height: 1.5;
   }
   
   @mixin font-size-base {
       font-size: $font-size-base;
       line-height: 1.5;
   }
   
   @mixin font-size-base-plus-1 {
       font-size: $font-size-base-plus-1;
       line-height: 1.35;
   }
   
   @mixin font-size-base-plus-2 {
       font-size: $font-size-base-plus-2;
       line-height: 1.35;
   }
   
   @mixin font-size-base-plus-3 {
       font-size: $font-size-base-plus-3;
       line-height: 1.2;
   }
   
   @mixin font-size-base-plus-4 {
       font-size: $font-size-base-plus-4;
       line-height: 1.2;
   }
   
   @mixin font-size-base-plus-5 {
       font-size: $font-size-base-plus-5;
       line-height: 1.1;
       text-transform: uppercase;
   }
   
   @mixin font-size-base-plus-6 {
       font-size: $font-size-base-plus-6;
       line-height: 1;
       text-transform: uppercase;
   }
   
   html {
       @include font-size-base;
   }
   
   h1 {
       @include font-size-base-plus-6;
   }
   
   h2, .c-col__heading-2 {
       @include font-size-base-plus-5;
   }
   
   h3, .c-col__heading-3 {
       @include font-size-base-plus-4;
   }
   
   h4, .c-col__heading-4 {
       @include font-size-base-plus-3;
   }
   
   h5, .c-col__heading-5 {
       @include font-size-base-plus-2;
   }
   
   h6, .c-col__heading-6 {
       @include font-size-base-minus-1;
   }