/*!
 * Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
@import "functions";
@import "variables";

:root, :host {
    --#{$fa-css-prefix}-style-family-classic: "#{ $fa-style-family }";
    --#{$fa-css-prefix}-font-solid: normal 900 1em/1 "#{ $fa-style-family }";
}

@font-face {
    font-family: "Font Awesome 6 Pro";
    font-style: normal;
    font-weight: 900;
    font-display: $fa-font-display;
    src: url("#{$fa-font-path}/fa-solid-900.woff2") format("woff2"),
        url("#{$fa-font-path}/fa-solid-900.ttf") format("truetype");
}

.fas,
.#{$fa-css-prefix}-solid {
    font-weight: 900;
}
