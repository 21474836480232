.tn-ticket-selector__controls-container {
    .tn-radio-button-list__button {
        @include c-btn($black, $white);
        border-radius: $border-radius-base;
        display: block;
        &:hover,
        &:focus {
            @include c-btn--hover($black, $white);
        }
        &:active {
            @include c-btn--active($black, $white);
        }
    }

    .tn-radio-button-list__input:checked+.tn-radio-button-list__button {
        @include c-btn;
        border-radius: $border-radius-base;
        display: block;
        &:hover,
        &:focus {
            @include c-btn--hover;
        }
        &:active {
            @include c-btn--active;
        }
    }
}

.tn-additional-events__btn-toggle-list-view {
    border-radius: $border-radius-base;
    padding: $padding-large-vertical $padding-base-horizontal;
    &[aria-expanded="true"] {
        border-radius: $border-radius-base $border-radius-base 0 0;
    }
}


.tn-additional-events__controls-container {
    .tn-additional-events__toggle-controls {
        margin-bottom: 0;
    }
    .tn-toggle-btn span.tn-toggle-btn__icon {
        border-left: 1px solid rgba($white, .2);
        color: rgba($white, .85);
    }
}

.tn-additional-events__continue-shopping-link {
    @include c-cta;
    &:hover, &:focus, &:active {
        @include c-cta--hover;
    }
}

.tn-ticket-selector__pricetype {
    gap: $spacer-s;
    @include mq($from: desktop) {
        gap: $spacer-m;
    }
}

.tn-additional-events__list {
    li {
        @include ff-heading;
        text-align: center;
        color: $white;
        margin-bottom: 0;
        &:nth-of-type(even) a {
            background-color: rgba($black, .75);
        }
        &:nth-of-type(odd) a {
            background-color: rgba($black, .55);
        }
        &:last-of-type a {
            border-radius: 0 0 $border-radius-base $border-radius-base;
        }
        a {
            display: block;
            padding: 0.5rem;
            &:hover, &:focus {
                color: $white;
                text-decoration: none;
                background-color: rgba($black, .95);
            }  
        }
    }
}

//
// Date and Heading
.tn-prod-season-header {
    .tn-prod-season-header__title {
        @include ff-heading;
        @include font-size-base-plus-5;
    }
}

.tn-event-detail__performance-details-container {
    .tn-event-detail__display-time {
        @include ff-heading;
        @include font-size-base-plus-2;
    }
}

//
// Timed Entry Zone Selector
.tn-zone-selector__option {
    .tn-radio-button-list__check-icon {
        display: none;
    }

    .tn-radio-button-list__input:checked+.tn-radio-button-list__button .tn-radio-button-list__check-icon {
        display: inline;
    }
}

.tn-ticket-selector__additional-controls {
    @include c-boxout;
}