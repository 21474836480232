.page-wrap {
    background-color: $page-background;
}

.content-wrap {
    padding: $spacer-s $spacer-m;
    @include mq($from: desktop) {
        padding: $spacer-s $spacer-l;

    }
}

.page-wrap .content-wrap {
    background: $white;
    padding-bottom: 50px;
    min-height: 90vh;
    @include mq($from: desktop) {
        margin-top: $spacer-l;
        box-shadow: 0px 4px 8px rgba($black, .15), 0px 3px 6px rgba($black, .05);
        border-radius: $radius--m $radius--m 0 0;
        max-width: calc(100% - $spacer-m);
    }
    @include mq($from: $max-site-width) {
        max-width: $max-site-width;
    }
}

.max-width {
    max-width: $max-site-width;
    margin: 0 auto;
}


$inuit-wrapper-width:   $max-site-width !default;
$inuit-wrapper-padding: $inuit-global-spacing-unit !default;

/**
 * Page-level constraining and wrapping elements.
 */

@if (type-of($inuit-wrapper-width) != number) {
    @error "`#{$inuit-wrapper-width}` needs to be a number.";
}

.o-wrapper {
    padding-right: $inuit-global-spacing-unit-small;
    padding-left:  $inuit-global-spacing-unit-small;
    margin-right: auto;
    margin-left:  auto;
    max-width: $inuit-wrapper-width;

    @include mq($from: tablet) {
        padding-right: $inuit-global-spacing-unit;
        padding-left:  $inuit-global-spacing-unit;
    }
}
