
.tn-plug-in-add-ons {
    .tn-plug-in-add-ons-item {
        grid-column-gap: $spacer-s;
        @include mq($from: desktop) {
            grid-column-gap: $spacer-m;
        }
    }

    .tn-plug-in-add-ons-item-heading {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .tn-plug-in-add-ons-zones {
        margin-top: $spacer-m;
        legend {
            @include ff-heading;
            @include font-size-base-plus-1;
        }
    }
    .tn-plug-in-add-ons-price-types-item {
        gap: $spacer-s;
        @include mq($from: desktop) {
            gap: $spacer-m;
        }
    }
    .tn-ticket-selector__pricetype-select-wrapper {
        min-width: 70px;
        flex-shrink: 0;
    }
    .tn-ticket-selector__pricetype-label-container {
        label {
            @include ff-heading;
            @include font-size-base;
        }
    }
}