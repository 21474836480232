//
// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-large-horizontal, $font-size-base, $line-height-base, $border-radius-base);
  @include user-select(none);
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }
  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }
  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    pointer-events: none; // Future-proof disabling of clicks
    @include opacity(.65);
    @include box-shadow(none);
  }
  @include c-btn;

  &:hover,
  &:focus {
      @include c-btn--hover;
  }
  &:active {
      @include c-btn--active;
  }
}

// Alternate buttons
// --------------------------------------------------
.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

// Success appears as green
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Link buttons
// -------------------------
// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;
  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------
.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}

.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}

// Block button
// --------------------------------------------------
.btn-block {
  display: block;
  width: 100%;
  max-width: 400px;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */

   $inuit-button-padding-vertical:         $inuit-global-spacing-unit-small !default;
   $inuit-button-padding-horizontal:       $inuit-global-spacing-unit-large !default;
   $inuit-button-transition:               $global-transition !default;
   $inuit-button-radius:                   $inuit-global-spacing-unit-large !default;
   
   $inuit-button-small-padding-vertical:   $inuit-global-spacing-unit-tiny !default;
   $inuit-button-small-padding-horizontal: $inuit-global-spacing-unit-small !default;
   
   $inuit-button-large-padding-vertical:   $inuit-global-spacing-unit !default;
   $inuit-button-large-padding-horizontal: $inuit-global-spacing-unit-large !default;
   
   $inuit-button-ghost-border-width:       2px;
   
   /**
    * 1. Allow us to style box model properties.
    * 2. Line different sized buttons up a little nicer.
    * 3. Make buttons inherit font styles (often necessary when styling `input`s as
    *    buttons).
    * 4. Reset/normalize some styles.
    * 5. Force all button-styled elements to appear clickable.
    */
   
   
   /* Global button styles (common to all variants)
      ========================================================================== */
   
   @mixin c-btn($bg-color: $primary, $txt-color: $white) {
       display: inline-block; /* [1] */
       vertical-align: middle; /* [2] */
       font: inherit; /* [3] */
       text-align: center; /* [4] */
       margin: 0; /* [4] */
       cursor: pointer; /* [5] */
       position: relative;
       appearance: none;
       border: none;
       background: $bg-color;
       color: $txt-color;
       padding: $inuit-button-padding-vertical $inuit-button-padding-horizontal;
       transition: $inuit-button-transition;
       border-radius: $inuit-button-radius;
       text-decoration: none;
       @include ff-heading;
   }
   
   @mixin c-btn--hover($bg-color: $highlight, $txt-color: $white, $bg-hover: mix($bg-color, $black, 90%)) {
       background: $bg-hover;
       color: $txt-color;
       box-shadow: rgba($black, .1) 0 4px 12px;
       transform: translateY(-1px);
   }
   
   @mixin c-btn--active($bg-color: $highlight, $txt-color: $white, $bg-hover: mix($bg-color, $black, 90%)) {
       background: $bg-hover;
       box-shadow: rgba($black, 0) 0 2px 4px;
       transform: translateY(0);
   }
   
   
   /* Button classes & variants
      ========================================================================== */
   
   
   .c-btn {
       @include c-btn;
   
       &:hover,
       &:focus {
           @include c-btn--hover;
       }
       &:active {
           @include c-btn--active;
       }
   }
   
   .c-btn--secondary {
       @include c-btn($black, $white);
   
       &:hover,
       &:focus {
           @include c-btn--hover($black, $white);
       }
       &:active {
           @include c-btn--active($black, $white);
       }
   }
   
   /* Size variants
      ========================================================================== */
   
   .c-btn--small {
       padding: $inuit-button-small-padding-vertical $inuit-button-small-padding-horizontal;
   
       &.c-btn--ghost {
           padding: ($inuit-button-small-padding-vertical - $inuit-button-ghost-border-width) ($inuit-button-small-padding-horizontal - $inuit-button-ghost-border-width);
       }
   }
   
   .c-btn--large {
       padding: $inuit-button-large-padding-vertical $inuit-button-large-padding-horizontal;
   
       &.c-btn--ghost {
           padding: ($inuit-button-large-padding-vertical - $inuit-button-ghost-border-width) ($inuit-button-large-padding-horizontal - $inuit-button-ghost-border-width);
       }
   }
   
   
   /* Ghost buttons
      Ghost buttons have see-through backgrounds and are bordered.
      ========================================================================== */
   
   @mixin c-btn--ghost($border-color: currentColor, $txt-color: currentColor) {
       border: $inuit-button-ghost-border-width solid $border-color;
       color: $txt-color;
       padding: ($inuit-button-padding-vertical - $inuit-button-ghost-border-width) ($inuit-button-padding-horizontal - $inuit-button-ghost-border-width);
       background: none !important;
   
       &:hover,
       &:focus {
           color: $txt-color;
           background: rgba($black, .05);
           border: $inuit-button-ghost-border-width solid $border-color;
           transform: translateY(-1px);
       }
   
       &:active {
           transform: translateY(0px);
       }
   }
   
   .c-btn--ghost {
       @include c-btn--ghost();
   }
   
   
   /* Form buttons
      ========================================================================== */
   
   input[type="submit"]:not([class]), input[type="button"]:not([class]), input[type="reset"]:not([class]),
   button[type="submit"]:not([class]), button[type="button"]:not([class]), button[type="reset"]:not([class]) {
       @include c-btn;
   
       &:hover,
       &:focus {
           @include c-btn--hover;
       }
   
       &:active {
           @include c-btn--active;
       }
   }
   
   
   /* Has Spinner
       ========================================================================== */
   
   .c-btn--has-spinner {
       display: inline;
   }
   
   .c-btn--has-spinner:after {
       content: "";
       opacity: 0;
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       background-color: inherit;
       z-index: 1;
       border-radius: $inuit-button-radius;
   }
   
   .c-btn--has-spinner:before {
       @include fa-reg;
       @extend .fas !optional;
       @extend .fa-spin !optional;
       @extend .fa-spinner-third !optional;
       opacity: 0;
       position: absolute;
       left: 50%;
       margin-left: -10px;
       line-height: inherit;
       z-index: 2;
       color: $white;
       display: inline-block;
   }
   
   .c-btn--has-spinner.active {
       opacity: 1;
       cursor: wait;
   
       &:before {
           opacity: 1;
       }
   
       &:after {
           opacity: 1;
       }
   }
   
   /* Mixin to add CTA arrow
      ========================================================================== */
   
   @mixin c-btn--cta {
       position: relative;
       padding-right: $inuit-button-padding-horizontal * 2;
   
       &::after {
           content: "\f054";
           @include fa-reg;
           position: absolute;
           @include v-center;
           right: $inuit-button-padding-horizontal;
       }
   }
   
   .c-btn--cta {
       @include c-btn--cta;
       &.c-btn--small {
           padding-right: $inuit-button-small-padding-horizontal * 3;
   
           &::after {
               right: $inuit-button-small-padding-horizontal;
           }
       }
   
       &.c-btn--large {
           padding-right: $inuit-button-large-padding-horizontal * 2;
   
           &::after {
               right: $inuit-button-large-padding-horizontal;
           }
       }
   }
   
   /* Disabled button style
      ========================================================================== */
   
   .c-btn--disabled{
       @include c-btn(black(10), black(50));
       pointer-events: none;
   }
   
   
   /*  Call to action
       ========================================================================== */
   
   @mixin c-cta($highlight-color: $highlight, $txt-color: $black) {
       display: inline-flex;
       align-items: baseline;
       cursor: pointer;
       position: relative;
       padding: 5px 0;
       @include ff-heading;
       text-decoration: none;
       --bg-h: 2px;
       background: linear-gradient(90deg,
       rgba($highlight-color, 1),
       rgba($highlight-color, 1) 50%,
       rgba($txt-color, .15) 50%,
       rgba($txt-color, .15)) no-repeat right bottom / 200% var(--bg-h);
       background-position-x: 100%;
       transition: background-position 350ms ease-in-out;
       padding-bottom: 2px;
       gap: .5em;
       &::after {
           @include fa-solid;
           content: "\f105";
           font-size: 90%;
           display: inline-block;
           color: currentColor;
           transition: transform 150ms ease-in-out;
       }
   
       // Sets a spacer on call to actions to display them as a block item
       p + & {
           &::before {
               display: block;
               margin-top: .4rem;
               content: "";
   
               @include mq($from: tablet) {
                   margin-top: .8rem;
               }
           }
       }
   
       // Reduces margin with two CTAs stacked on top of each other
       & + & {
           &::before {
               margin-top: .2rem;
   
               @include mq($from: tablet) {
                   margin-top: .4rem;
               }
           }
       }
   }
   
   @mixin c-cta--hover() {
       --bg-h: 2px;
       background-size: 200% var(--bg-h);
       background-position-x: 0%;
       transition: background-position 350ms ease-in-out;
       &::after {
           transform: translateX(.085em);
           transition: transform 350ms ease-in-out;
       }
   }
   
   
   .c-cta {
       @include c-cta;
       &:hover, &:focus, &:active {
           @include c-cta--hover;
       }
   }
   
   
   /* Accessibility Skip link button
      ========================================================================== */
   
   .c-btn--skip-link {
       @include c-btn;
       margin: 0 auto;
       position: absolute;
       z-index: 2000;
       left: $spacer-s;
       right: $spacer-s;
       top: 1em;
       max-width: 20em;
       opacity: 1;
       &:hover,
       &:active,
       &:focus {
           @include c-btn--hover;
       }
   }
   
   .c-btn--skip-link:not(:focus):not(:hover) {
       -webkit-transform: translateY(-4em);
       -ms-transform: translateY(-4em);
       transform: translateY(-4em);
       opacity: 0;
   }
   
