/* ==========================================================================
   #BUTTONS
   ========================================================================== */

   $inuit-button-padding-vertical:         $inuit-global-spacing-unit-small !default;
   $inuit-button-padding-horizontal:       $inuit-global-spacing-unit-large !default;
   $inuit-button-transition:               $global-transition !default;
   $inuit-button-radius:                   $inuit-global-spacing-unit-large !default;
   
   $inuit-button-small-padding-vertical:   $inuit-global-spacing-unit-tiny !default;
   $inuit-button-small-padding-horizontal: $inuit-global-spacing-unit-small !default;
   
   $inuit-button-large-padding-vertical:   $inuit-global-spacing-unit !default;
   $inuit-button-large-padding-horizontal: $inuit-global-spacing-unit-large !default;
   
   $inuit-button-ghost-border-width:       2px;
   
   /**
    * 1. Allow us to style box model properties.
    * 2. Line different sized buttons up a little nicer.
    * 3. Make buttons inherit font styles (often necessary when styling `input`s as
    *    buttons).
    * 4. Reset/normalize some styles.
    * 5. Force all button-styled elements to appear clickable.
    */

@mixin c-btn($bg-color: $primary, $txt-color: $white) {
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    font: inherit; /* [3] */
    text-align: center; /* [4] */
    margin: 0; /* [4] */
    cursor: pointer; /* [5] */
    position: relative;
    appearance: none;
    border: none;
    background: $bg-color;
    color: $txt-color;
    transition: $inuit-button-transition;
    border-radius: $inuit-button-radius;
    text-decoration: none;
    @include ff-heading;
}

@mixin c-btn--hover($bg-color: $highlight, $txt-color: $white, $bg-hover: mix($bg-color, $black, 90%)) {
    background: $bg-hover;
    color: $txt-color;
    box-shadow: rgba($black, .1) 0 4px 12px;
    transform: translateY(-1px);
}

@mixin c-btn--active($bg-color: $highlight, $txt-color: $white, $bg-hover: mix($bg-color, $black, 90%)) {
    background: $bg-hover;
    box-shadow: rgba($black, 0) 0 2px 4px;
    transform: translateY(0);
}


/* Ghost buttons
   Ghost buttons have see-through backgrounds and are bordered.
   ========================================================================== */

@mixin c-btn--ghost($border-color: currentColor, $txt-color: currentColor) {
    border: $inuit-button-ghost-border-width solid $border-color;
    color: $txt-color;
    padding: ($inuit-button-padding-vertical - $inuit-button-ghost-border-width) ($inuit-button-padding-horizontal - $inuit-button-ghost-border-width);
    background: none !important;

    &:hover,
    &:focus {
        color: $txt-color;
        background: rgba($black, .05);
        border: $inuit-button-ghost-border-width solid $border-color;
        transform: translateY(-1px);
    }

    &:active {
        transform: translateY(0px);
    }
}

.c-btn--ghost {
    @include c-btn--ghost();
}

/*  Call to action
    ========================================================================== */

    @mixin c-cta($highlight-color: $highlight, $txt-color: $black) {
        display: inline-flex;
        align-items: baseline;
        cursor: pointer;
        position: relative;
        padding: 5px 0;
        @include ff-heading;
        text-decoration: none;
        --bg-h: 2px;
        background: linear-gradient(90deg,
        rgba($highlight-color, 1),
        rgba($highlight-color, 1) 50%,
        rgba($txt-color, .15) 50%,
        rgba($txt-color, .15)) no-repeat right bottom / 200% var(--bg-h);
        background-position-x: 100%;
        transition: background-position 350ms ease-in-out;
        padding-bottom: 2px;
        gap: .5em;
        &::after {
            @include fa-solid;
            content: "\f105";
            font-size: 90%;
            display: inline-block;
            color: currentColor;
            transition: transform 150ms ease-in-out;
        }
    }
    
    @mixin c-cta--hover() {
        --bg-h: 2px;
        background-size: 200% var(--bg-h);
        background-position-x: 0%;
        transition: background-position 350ms ease-in-out;
        &::after {
            transform: translateX(.085em);
            transition: transform 350ms ease-in-out;
        }
    }
    
    
    .c-cta {
        @include c-cta;
        &:hover, &:focus, &:active {
            @include c-cta--hover;
        }
    }
    

