///* ========================================================================
//   #COLOURS
//   ======================================================================== */
// The list of colour variables used globally across the project


// Universal Colours
// Generic colours that can be used for your project.
// You shouldn't need to edit these, but can if the project requires it.


//
// White/Black
//

$white: #FFFFFF;
$off-white: #F3F2ED;

$black: #050609;

//
// Greys – shades of grey created from the black variable.
//

@function black($level) { // use e.g. black(10) to output a light grey color.
    @return mix($black, $white, $level * 1%);
}

$black--10: black(10);
$black--20: black(20);
$black--30: black(30);
$black--40: black(40);
$black--50: black(50);
$black--60: black(60);
$black--70: black(70);
$black--80: black(80);
$black--90: black(90);

$grey: black(50);

//
// Warnings
//
$warning: #F75B21;
$error: #DE1436;
$success: #5FB760;


//
// Social Colours
//
$twitter: #00ACED;
$facebook: #3B5998;
$linkedin: #007BB6;
$youtube: #BB0000;
$tumblr: #32506D;
$instagram: #E44552;
$vimeo: #00ADEF;


// Project specific colours.
// Colours taken from brand guidelines or designs specific to this project.

$tnmRed: #FE0011; // Bright color used as a highlight
$tnmRedAdjusted: #EE0221; // Slightly adjusted to maintain a 4.5:1 contrast with white, for buttons etc.
$tnmVanilla: #EFEEA1;

// Core Colours
$tnmBlack: $black;
$tnmDarkGray: #222B30;
$tnmBlue: #0D21A1;
$tnmYellow: #F6F740;
$tnmCyan: #80FFDB;
$tnmMagenta: #DD1155;
$tnmPurple: #8338EC;
$tnmGreen: #35FF69;
$tnmAqua: #1BE7FF;

// Day/Sandy Colours
$tnmDayBlack: #212529;
$tnmDaySand: #FFEEC2;
$tnmDayDarkSand: #E5D5A4;
$tnmDayYellow: #FFBE0B;
$tnmDayBlue: #0D21A1;
$tnmDayRed: #D52941;


// Gradients
$tnmGreenYellowGrad: linear-gradient(90deg, #3AD59F, #4EDE93, #66E685, #80ED75, #9BF362, #B9F94C, #D7FD32, #F8FF00);
$tnmPurpleBlueGrad: linear-gradient(90deg, #8338EC, #7435E2, #6632D7, #572FCC, #482BC2, #3828B7, #2625AC, #0D21A1);

//
// Project Colours as variables
//
$highlight: $tnmRedAdjusted;
$primary: $tnmRed;
$secondary: $tnmVanilla;


// Site Width
$max-site-width: 1400px;
$max-form-width: 600px;

// Radii
$radius--s: 4px;
$radius--m: 8px;
$radius--l: 12px;

// Standardise some UI treatments.
$global-radius: $radius--m !default;

//Form element heights
$field-height-mobile: 2.44rem;
$field-height-desk: 3rem;
$field-height-large: 3.8rem;

// Spacer variables
// based on inuit's global spacing values, with nicer names
// see _settings.core.scss
$spacer-xs: $inuit-global-spacing-unit-tiny;
$spacer-s: $inuit-global-spacing-unit-small;
$spacer-sm: $inuit-global-spacing-unit-small + $inuit-global-spacing-unit-tiny;
$spacer-m: $inuit-global-spacing-unit;
$spacer-ml: $inuit-global-spacing-unit + $inuit-global-spacing-unit-small;
$spacer-l: $inuit-global-spacing-unit-large;
$spacer-xl: $inuit-global-spacing-unit-huge;


//
// Boxout Mixin
@mixin c-boxout($base-color: $black) {
    background-color: rgba($base-color, .075);
    border: 1px solid rgba($base-color, .05);
    color: $base-color;
    padding: $spacer-s $spacer-sm;
    margin: 0 0 $spacer-s 0;
    border-radius: $radius--m;
    @include mq($from: desktop) {
        border-radius: $radius--l;
        padding: $spacer-sm $spacer-m;
    }
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}