.tn-btn-datepicker__icon-container {
	.glyphicon {
		color: $white;
	}
}

.tn-event-listing-view__results-container {
	// Calendar Title Color
	.tn-event-listing-view__results-heading {
		color: $black;
	}
	// Calendar Day Number
	.tn-events-calendar__day-number {
		color: $black;
	}
	// Calendar Heading
	.tn-events-calendar__table th, .tn-events-calendar__table thead {
		background-color: $gray-dark;
	}
}

// Tab color
.tn-events-listing-page {
	.tn-event-listing-mode-tab-nav__list-item {
		color: $black;
	}
}

.tn-events-listing-page .tn-event-listing-mode-tab-nav__list-item {
	@include ff-heading;
}

//
// Calendar View
.tn-events-calendar__event.btn-primary {
	border-radius: $radius--s;
}

.tn-events-calendar__table {
	.tn-events-calendar__day-cell--today {
		background-color: rgba($highlight, 0.15);
	}
	th {
		@include ff-heading;
	}
}

//
// List View
.tn-event-listing-view__results-container {
	.tn-prod-list-item__property--heading {
		@include ff-heading;
		text-transform: uppercase;
		@include font-size-base-plus-4;
	}
	.tn-performance-title p {
		margin-bottom: 0;
	}
}